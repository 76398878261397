import Modal from "@mui/material/Modal";
import { Container, Content, Description, Title, Button } from "./components";

import { IconWarningTransfer } from "atoms/IconWarningTransfer/IconWarningTransfer";
import { Box } from "@mui/material";

interface Props {
  isOpen: boolean;
  handler: () => void;
}

export const WarningTransferAlertMobile = ({ isOpen, handler }: Props) => {
  return (
    <Modal open={isOpen}>
      <Container>
        <Content>
          <Title>
            Внимание!
            <br /> Перевод нельзя отменить
          </Title>
          <Description>
            Проверьте внимательно реквизиты получателя, не переводите деньги
            незнакомым людям.
          </Description>
          <Button onClick={handler}>Понятно</Button>
        </Content>
      </Container>
    </Modal>
  );
};
