import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import theme from "theme";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { useForm, Controller } from "react-hook-form";
import { AuthContainer } from "containers";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { confirmResetPassword, authActions } from "store/slices/auth";
import { authSelector } from "store";
import { CheckBlock } from "components/CheckBlock/CheckBlock";
import { ErrorScreen } from "components/ErrorScreen/ErrorScreen";
import { isServerError } from "utils/isServerError";

const ThirdScreen = () => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(authSelector);

  const { control, watch, setValue } = useForm<{
    password: string;
    confirmPassword: string;
  }>({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [password, confirmedPassword] = watch(["password", "confirmPassword"]);
  const [isDisabledCheck, setIsDisabledCheck] = useState(true);

  const [repeatPasswordFocused, setRepeatPasswordFocused] = useState(false);
  const [errorFirstField, setErrorFirstField] = useState("");

  const handleConfirmReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(confirmResetPassword(password));
  };

  const isDisabledChecking = useCallback((value: boolean) => {
    setIsDisabledCheck(value);
  }, []);

  useEffect(() => {
    if (repeatPasswordFocused && !isDisabledCheck) {
      setErrorFirstField("Пароль не соответствует требованиям");
    }
    if (isDisabledCheck) {
      setErrorFirstField("");
    }
  }, [repeatPasswordFocused, isDisabledCheck]);

  if (isServerError(error)) {
    return (
      <Box px={{ xs: "30px" }}>
        <ErrorScreen
          title="Произошла ошибка</br>во время восстановления пароля"
          subTitle="Попробуйте снова"
          buttonTitle="Восстановить пароль"
          buttonStyles={{
            maxWidth: "376px",
          }}
          buttonHandler={() => {
            setValue("password", "");
            setValue("confirmPassword", "");
            dispatch(authActions.resetError());
          }}
        />
      </Box>
    );
  }

  return (
    <AuthContainer title="Восстановить пароль">
      <Box
        px={{ xs: "20px", md: "30.5px" }}
        py={32}
        sx={{
          backgroundColor: theme.palette.gray.b50,
        }}
        border={`1px solid ${theme.palette.gray.b100}`}
        borderRadius={24}
        component="form"
        autoComplete="off"
      >
        <Box component="form" autoComplete="off">
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <PasswordInput
                {...field}
                // @ts-ignore
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (error) dispatch(authActions.resetError());
                  field.onChange(e.target.value);
                }}
                label="Придумайте пароль"
                placeholder="Введите пароль"
                error={error?.comment || errorFirstField}
              />
            )}
          />
          <Box mt={20} mb={20}>
            <Controller
              control={control}
              name="confirmPassword"
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  // @ts-ignore
                  value={confirmedPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (error) dispatch(authActions.resetError());
                    field.onChange(e.target.value);
                  }}
                  label="Повторите пароль"
                  placeholder="Введите пароль"
                  error={error?.comment || ""}
                  checkInputFocus={setRepeatPasswordFocused}
                />
              )}
            />
          </Box>
          <CheckBlock
            value={password}
            isDisabled={isDisabledChecking}
            type="password"
            isPasswordMatch={password === confirmedPassword}
            isFocus={repeatPasswordFocused}
          />
          <Box mt={98}>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={
                !isDisabledCheck ||
                Boolean(error?.comment) ||
                password !== confirmedPassword
              }
              isLoading={isLoading}
              fullWidth
              onClick={(e) => handleConfirmReset(e)}
            >
              Продолжить
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthContainer>
  );
};

export default ThirdScreen;
