import { useState, useCallback } from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { IconWarningTriangle } from "atoms/IconWarningTriangle/IconWarningTriangle";
import { Button } from "components";
import { CARD_STATUS, FROZEN_BLOCK_CARDS } from "components/CardsInfo/model";
import IconFire from "components/Icons/IconFire";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsPWA } from "hooks/useIsPWA";
import theme from "theme";
import {
  CARD_ACTION,
  setCardError,
  setScreen,
  unblockedCard,
} from "store/slices/cardsSlice";
import { cardsSelector, useAppThunkDispatch } from "store";
import RightIcon from "./assets/RightIcon.png";
import { IconSnow } from "atoms/IconSnow/IconSnow";
import {
  IconCreditCardClosed,
  IconCreditCardFrozen,
} from "atoms/IconCards/IconCards";
import IconWrapper from "components/IconWrapper/IconWrapper";
import { IconAlertTriangle } from "atoms/IconAlertTriangle/IconAlertTriangle";

export const CardButtons = ({
  card,
  dataIcon,
  handleClick,
}: {
  card: any;
  dataIcon: any;
  handleClick: (action: CARD_ACTION) => void;
}) => {
  const [activeIndexItem, setActiveIndexItem] = useState<number | null>(null);

  const { isMobile, size } = useIsMobile();
  const isPWA = useIsPWA();
  const { isBlockLoading } = useSelector(cardsSelector);
  const dispatch = useAppThunkDispatch();

  const unFreezeCard = () => {
    dispatch(unblockedCard({ cardId: card?.cardId }))
      .unwrap()
      .catch((res) => {
        const { code, title, subtitle } = res.response.data;

        dispatch(setCardError({ code, title, subtitle }));
        if (code === "CHANGE_CARD_STATUS_RESPONSE_ERROR_CODE") {
          dispatch(setScreen(CARD_ACTION.UNSUCCESS_STATUS_CHANGE));
        } else {
          dispatch(setScreen(CARD_ACTION.UNSUCCESS));
        }
      });
  };

  const getFrozenCardSubtitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Операции по карте приостановлены, ее нельзя пополнить, снять или перевести с нее деньги.\nДля продолжения операций по карте нажмите кнопку "Разморозить"`;
      case "sm":
        return `Операции по карте приостановлены, ее\nнельзя пополнить, снять или перевести\nс нее деньги. Для продолжения операций\nпо карте нажмите кнопку "Разморозить"`;
      case "xs":
        return `Операции по карте приостановлены, ее\nнельзя пополнить, снять или перевести\nс нее деньги. Для продолжения операций\nпо карте нажмите кнопку "Разморозить"`;
      default:
        return `Операции по карте приостановлены, ее нельзя пополнить, снять или перевести с нее деньги. Для продолжения операций по карте нажмите кнопку "Разморозить"`;
    }
  }, [size]);

  const cardIsBlocked = (status: string) => {
    return (
      <Box
        // order={
        //   card.status === CARD_STATUS.Active
        //     ? { xs: 4, md: 4 }
        //     : { xs: 2, md: 4 }
        // }
        bgcolor={status === CARD_STATUS.Closed ? "#F5F5F5" : "#FFF4F4"}
        p={12}
        borderRadius={20}
        display="flex"
        flexDirection="column"
        alignItems="self-start"
        mt={{ xs: 12, md: 12 }}
        mb={{ xs: 12, md: 20 }}
      >
        <IconWrapper>
          {status === CARD_STATUS.Closed ? (
            <IconCreditCardClosed />
          ) : (
            <IconAlertTriangle />
          )}
        </IconWrapper>
        <Typography
          color={theme.palette.gray.b800}
          fontWeight={500}
          mt={8}
          mb={{ xs: 4 }}
          sx={{
            color: "#393E46",
            fontSize: "17px",
            fontWeight: 500,
            lineHeight: "22px",
          }}
        >
          {status === CARD_STATUS.Closed
            ? "Карта закрыта"
            : " Ваша карта заблокирована"}
        </Typography>
        {status !== CARD_STATUS.Closed && (
          <Typography
            sx={{
              color: "#393E46",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
            }}
          >
            Для получения новой карты обратитесь
            <br /> в обслуживающее полевое учреждение
          </Typography>
        )}
        {status === CARD_STATUS.Closed && (
          <Typography
            sx={{
              color: "#393E46",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
            }}
          >
            Обратитесь в контакт-центр банка по телефону
            <br />
            <Link
              href="tel:+78002004567"
              variant="text_5"
              color="#739B67"
              fontWeight={400}
              component="a"
              sx={{
                textDecoration: "none",
              }}
            >
              8-800-200-45-67
            </Link>{" "}
            или{" "}
            <Link
              href="tel:+78126030090"
              variant="text_5"
              color="#739B67"
              fontWeight={400}
              component="a"
              sx={{
                textDecoration: "none",
              }}
            >
              +7-812-603-00-90
            </Link>
          </Typography>
        )}
      </Box>
    );
  };

  const cardIsFrozen = () => {
    return (
      <Box
        order={
          card.status === CARD_STATUS.Active
            ? { xs: 4, md: 4 }
            : { xs: 2, md: 4 }
        }
        bgcolor="#E8F1FF"
        borderRadius={20}
        display="flex"
        flexDirection="column"
        alignItems="self-start"
        p={12}
        mt={{ xs: 12, md: 12 }}
        mb={{ xs: 12, md: 20 }}
      >
        <Box
          sx={{
            padding: "8px",
            backgroundColor: "#fff",
            borderRadius: "11px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconCreditCardFrozen />
        </Box>

        <Typography
          color={theme.palette.gray.b800}
          fontWeight={500}
          mt={8}
          mb={{ xs: 4 }}
          sx={{
            color: "#393E46",
            fontSize: "17px",
            fontWeight: 500,
            lineHeight: "22px",
          }}
        >
          {card.status === CARD_STATUS.Temporary
            ? "Карта заморожена Банком"
            : "Карта заморожена"}
        </Typography>

        <Typography
          whiteSpace="pre-wrap"
          sx={{
            color: "#393E46",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          {card.status === CARD_STATUS.Temporary ? (
            <>
              Для «разморозки» карты обратитесь в контакт-центр банка по
              телефону{" "}
              <Link
                href="tel:+78002004567"
                color="#739B67"
                fontWeight={400}
                component="a"
                sx={{
                  textDecoration: "none",
                }}
              >
                8-800-200-45-67
              </Link>{" "}
              или
              <br />
              <Link
                href="tel:+78126030090"
                color="#739B67"
                fontWeight={400}
                component="a"
                sx={{
                  textDecoration: "none",
                }}
              >
                +7-812-603-00-90
              </Link>
            </>
          ) : (
            getFrozenCardSubtitle()
          )}
        </Typography>
      </Box>
    );
  };

  const isButtonsShowed =
    !FROZEN_BLOCK_CARDS.includes(card.status) ||
    card.status === CARD_STATUS.Active;

  const isActive = card.status === CARD_STATUS.Active;
  const isFrozenReferral = card.status === CARD_STATUS.Referral;

  return (
    <Box mt={28}>
      <Typography
        sx={{
          color: "#454A3F",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        Действия с картой
      </Typography>
      {(card.status === CARD_STATUS.Lost ||
        card.status === CARD_STATUS.Stolen ||
        card.status === CARD_STATUS.Compromised ||
        card.status === CARD_STATUS.Closed) &&
        cardIsBlocked(card.status)}
      {(card.status === CARD_STATUS.Temporary ||
        card.status === CARD_STATUS.Referral) &&
        cardIsFrozen()}
      {isButtonsShowed && (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          boxSizing="border-box"
          rowGap={12}
          mt={12}
          order={{ xs: 2, md: 3 }}
        >
          {card?.status &&
            dataIcon?.map((cardItem) => {
              const isUnFrizeBtn =
                isFrozenReferral && cardItem.id === CARD_STATUS.Referral;

              return (
                <Box
                  order={{ xs: 4, md: 4 }}
                  key={cardItem.id}
                  width="100%"
                  p={12}
                  bgcolor={
                    activeIndexItem === cardItem.id
                      ? "var(--main-color-white)"
                      : "var(--main-color-bg-widgets)"
                  }
                  boxSizing="border-box"
                  display="flex"
                  gap={12}
                  alignItems="center"
                  borderRadius="16px"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    cursor:
                      isActive || isUnFrizeBtn ? "pointer" : "not-allowed",
                    pointerEvents: isActive || isUnFrizeBtn ? "auto" : "none",
                    opacity: isActive || isUnFrizeBtn ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (isFrozenReferral) {
                      unFreezeCard();
                    } else {
                      handleClick(cardItem.id);
                    }
                  }}
                  onMouseEnter={() => setActiveIndexItem(cardItem.id)}
                  onMouseLeave={() => setActiveIndexItem(null)}
                >
                  <IconButton
                    sx={{
                      backgroundColor: "var(--main-color-white)",
                      borderRadius: 11,
                      width: isMobile ? 36 : 40,
                      height: isMobile ? 36 : 40,
                      ".MuiButtonBase-root.Mui-disabled": {
                        backgroundColor: "var(--main-color-white)",
                      },
                    }}
                  >
                    {isUnFrizeBtn ? cardItem.svg2 : cardItem.svg()}
                  </IconButton>
                  <Box display="flex" alignItems="start">
                    <Typography
                      sx={{
                        color: "#454A3F",
                        fontSize: "17px",
                        fontWeight: 500,
                        lineHeight: "20px",
                      }}
                    >
                      {isUnFrizeBtn ? cardItem.title2 : cardItem.title}
                    </Typography>
                  </Box>
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "10px",
                    }}
                  >
                    <img src={RightIcon} />
                  </div>
                </Box>
              );
            })}
        </Box>
      )}
    </Box>
  );
};
