import { FC } from "react";
import { Box, Typography } from "@mui/material";
import theme from "theme";
import { Button } from "components";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { useAppThunkDispatch } from "store";
import { loadAttachment } from "store/slices/notifications";
import { useIsMobile } from "hooks/useIsMobile";
import { IconDownload } from "atoms/IconDownload/IconDownload";

interface INewsInfo {
  title?: string;
  subTitle?: string;
  date?: string;
  attachment?: number;
}

export const NewsInfo: FC<INewsInfo> = ({
  title,
  date,
  attachment,
  subTitle,
}) => {
  const { isMobile } = useIsMobile();
  const thunkDispatch = useAppThunkDispatch();
  const { blue, gray } = theme.palette;
  const formattedDate = date
    ? format(new Date(date), "d MMMM yyyy", { locale: ru })
    : "";

  const handleFileDownload = () => {
    if (attachment) {
      thunkDispatch(loadAttachment(attachment))
        .unwrap()
        .then((res) => {
          //@ts-ignore
          const base64Data = res.base64.split(";base64,").pop();
          //@ts-ignore
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: res.mimeType });

          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = res.fileName || "downloadedFile.pdf";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
    }
  };

  return (
    <Box pl={isMobile ? 20 : 30} pr={isMobile ? 20 : 30}>
      <Box alignItems="center" display="flex" gap={10} mb={20}>
        <Box bgcolor={blue.b50} borderRadius={12} paddingX={10} pt={3} pb={5}>
          <Typography variant="text_1">Новости банка</Typography>
        </Box>
        <Typography color={gray.b10} variant="text_6" fontWeight={600}>
          ·
        </Typography>
        <Typography color={gray.b10} variant="text_1">
          {formattedDate}
        </Typography>
      </Box>
      <Box mb={20}>
        <Typography fontWeight={500} variant="text_6">
          {title}
        </Typography>
      </Box>
      <Box mb={80}>
        <Typography variant="text_5">{subTitle}</Typography>
      </Box>
      {attachment && (
        <Button
          variant="primary"
          size="lg"
          fullWidth
          onClick={handleFileDownload}
          startIcon={<IconDownload color="#FFFFFF" />}
        >
          Скачать файл
        </Button>
      )}
    </Box>
  );
};
