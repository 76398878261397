import { AuthContainer } from "containers";
import { FC, useState } from "react";
import { Box } from "@mui/material";
import { PushIcon } from "../../atoms/PushIcons/PushIcon";
import { Button, Loader } from "components";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import { requestPermission } from "../../firebase";
import { handleTogglePush } from "../../store/slices/notifications";
import { showErrorMessage } from "../../store/slices/alerts";
import { PushError } from "pages/profile/ProfileTogglePush";
import styles from "./styles.module.css";

export const Push: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceIdItem = localStorage.getItem("PWADeviceId");
  const [isLoading, seiIsLoading] = useState(false);
  const [error, setShowError] = useState(false);

  const displayError = (message: string) => {
    dispatch(
      showErrorMessage({
        errorTitle: "Ошибка",
        errorMessage: message,
      })
    );
  };

  const handlePushToggle = async (status: boolean) => {
    seiIsLoading(true);
    requestPermission()
      .then((res) => {
        dispatch(
          handleTogglePush({
            deviceId: deviceIdItem || "",
            fcm: res.token || "",
            enabled: true,
            device_type: "W",
          })
        )
          // @ts-ignore
          .unwrap()
          .then(() => {
            localStorage.setItem("isPushOn", "true");
            navigate("/", { replace: true });
          })
          .catch(() => {
            displayError("Проверьте соединение");
          });
      })
      .catch((err) => {
        if (err.alert) {
          setShowError(true);
        } else {
          displayError(err.text);
        }
      })
      .finally(() => {
        seiIsLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      {isLoading && (
        <Box
          width="100%"
          height="calc(100% - 150px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "azure",
            opacity: 0.4,
            zIndex: 10,
          }}
        >
          <Loader />
        </Box>
      )}
      <PushIcon />
      <p className={styles.title}>
        Разрешить приложению
        <br />
        отправлять вам push-уведомления?
      </p>
      <p className={styles.description}>
        Вам нужно разрешить отправку
        <br />
        push-уведомлений в системных
        <br />
        настройках устройства.
      </p>
      <Button
        type="submit"
        onClick={() => {
          handlePushToggle(true);
        }}
        variant="primary"
        size="lg"
        fullWidth
        className={styles.btn}
      >
        Разрешить
      </Button>
      <Button
        type="submit"
        onClick={() => {
          navigate("/", { replace: true });
        }}
        variant="secondary"
        size="lg"
        fullWidth
        style={{
          marginTop: 10,
          backgroundColor: "var(--main-color-bg-widgets)",
          color: "var(--brand-color-primary)",
          height: "56px",
        }}
      >
        Не разрешать
      </Button>
      {error && <PushError setShowError={setShowError} />}
    </div>
  );
};
