import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { profileActions, updateLogin } from "store/slices/profile";
import { NotificationResult } from "molecules/NotificationResult";
import { profileSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { LoginInput } from "molecules/LoginInput/LoginInput";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "hooks/useResetStore";
import { authActions } from "store/slices/auth";
import { CheckBlock } from "components/CheckBlock/CheckBlock";
import { ErrorScreen } from "components/ErrorScreen/ErrorScreen";
import { isServerError } from "utils/isServerError";
import { IconLoginChangeSuccess } from "atoms/IconLoginChangeSuccess/IconLoginChangeSuccess";

type LoginModalProps = {
  login?: string;
  onClose: () => void;
};

export const LoginModal = ({ onClose }: LoginModalProps) => {
  const [newLogin, setNewLogin] = useState("");
  const [isDisabledCheck, setIsDisabledCheck] = useState(true);
  const dispatch = useDispatch();
  const { error, isLoading, isLoginUpdated } = useSelector(profileSelector);
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const resetStore = useResetStore();

  const isDisabledChecking = useCallback((value: boolean) => {
    setIsDisabledCheck(!value);
  }, []);

  const handleClick = () => {
    dispatch(updateLogin(newLogin));
  };

  const clickSuccess = () => {
    dispatch(authActions.setLogout());
    const deviceIdItem = localStorage.getItem("deviceId");
    const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    localStorage.clear();
    if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
    if (PWAdeviceIdItem !== null)
      localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    sessionStorage.clear();
    navigate("/sign-in", { replace: true });
    resetStore();
    onClose();
  };

  useEffect(() => {
    return () => {
      dispatch(profileActions.clearError());
    };
  }, [dispatch]);

  if (isServerError(error)) {
    return (
      <ErrorScreen
        title="Не удалось изменить логин"
        subTitle="Попробуйте снова"
        buttonTitle="Изменить логин"
        buttonHandler={() => {
          setNewLogin("");
          dispatch(profileActions.clearError());
        }}
        buttonStyles={{
          height: 56,
        }}
        isProfile
      />
    );
  }

  if (isLoginUpdated) {
    return (
      <Box
        pt="64px"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          title={`Ваш логин\nбыл успешно изменён`}
          icon={<IconLoginChangeSuccess />}
          bottomSpace={0}
          buttons={[
            {
              name: "Войти",
              variant: "primary",
              size: "lg",
              onClick: clickSuccess,
            },
          ]}
          buttonsMarginTop={30}
          titleStyle={{
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "36px",
            textAlign: "center",
            color: "var(--main-color-notification-title)",
          }}
          subtitleStyle={{
            fontWeight: 400,
            lineHeight: "28px",
            textAlign: "center",
            fontSize: "20px",
            color: "var(--main-color-notification-title)",
          }}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" px={{ xs: 16, xl: 32 }}>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        mt={{ xs: 20, xl: 24 }}
      >
        <LoginInput
          value={newLogin}
          label="Придумайте логин"
          placeholder="Введите логин"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error) dispatch(profileActions.clearError());
            setNewLogin(e.target.value.trim());
          }}
          // @ts-ignore
          error={error?.title || ""}
          isProfile
        />
        <CheckBlock
          value={newLogin}
          isDisabled={isDisabledChecking}
          type="login"
          isProfile
        />
        <Button
          isLoading={isLoading}
          disabled={isDisabledCheck || Boolean(error?.title)}
          size="lg"
          variant="primary"
          onClick={handleClick}
          style={{ marginTop: "20px" }}
        >
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};
