import styles from "./styles.module.css";
import ErrorIcon from "./assets/ErrorIcon.png";
import { Button } from "components";
import { IconLoginChangeError } from "atoms/IconLoginChangeError/IconLoginChangeError";

interface ErrorScreenProps {
  title: string;
  subTitle: string;
  buttonTitle: string;
  buttonHandler: () => void;
  buttonStyles?: object;
  isProfile?: boolean;
}

export const ErrorScreen = ({
  title,
  subTitle,
  buttonTitle,
  buttonHandler,
  buttonStyles,
  isProfile,
}: ErrorScreenProps) => {
  return isProfile ? (
    <div className={styles.containerProfile}>
      <IconLoginChangeError />
      <p
        className={styles.titleProfile}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p className={styles.subTitleProfile}>{subTitle}</p>
      <Button
        size="lg"
        fullWidth
        variant="primary"
        style={buttonStyles}
        onClick={buttonHandler}
      >
        {buttonTitle}
      </Button>
    </div>
  ) : (
    <div className={styles.container}>
      <img src={ErrorIcon} className={styles.icon} />
      <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p className={styles.subTitle}>{subTitle}</p>
      <Button
        size="lg"
        fullWidth
        variant="primary"
        style={buttonStyles}
        onClick={buttonHandler}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};
