import React, { useState, useEffect } from "react";
import { PhoneInput } from "molecules/PhoneInput/PhoneInput";
import { DateInput } from "components/DateInput";
import { Box } from "@mui/material";
import { Button } from "components";
import styles from "./style.module.css";
import { BackButtonArrow } from "components/BackButtonArrow";
import { CardInput } from "molecules/CardInput/CardInput";
import { PhoneCodesSelect } from "molecules/PhoneCodesSelect";
import { PhoneCode } from "../models";
import { useDispatch, useSelector } from "react-redux";
import { register, authActions } from "store/slices/auth";
import { authSelector } from "store";
import { Controller, useForm } from "react-hook-form";
import { formatValueByMask, getSortedCountries } from "utils";
import { fetchCountriesList } from "store/slices/auth/asyncThunks";
import { differenceInYears, isMatch } from "date-fns";
import { useNavigate } from "react-router-dom";
import { hideErrorMessage } from "store/slices/alerts";
import { useIsMobile } from "hooks/useIsMobile";
import { downloadPDF } from "utils/downloadPDF";

const FirstScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const { isLoading, error, countries } = useSelector(authSelector);
  const [phoneCode, setPhoneCode] = useState<PhoneCode | null>(null);
  const [phoneInputFocused, setPhoneInputFocused] = useState<boolean>(false);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{
    pan: string;
    phone: string;
    birthdate: string;
  }>({
    mode: "onChange",
    defaultValues: {
      pan: "",
      phone: "",
      birthdate: "",
    },
  });

  const [pan, phone, birthdate] = watch(["pan", "phone", "birthdate"]);
  const [isDateValid, setIsDateValid] = useState("");

  const handleChangePhoneCode = (phoneCode: PhoneCode) => {
    setPhoneCode(phoneCode);
  };

  const handleRegistry = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch(
      register({
        pan,
        phone: `${phoneCode?.code}${phone}`,
        birthdate: formatValueByMask("XX-XX-XXXX", birthdate)
          .split("-")
          .reverse()
          .join("-"),
      })
    )
      //@ts-ignore
      .unwrap()
      .then(() => dispatch(authActions.setRegisterPhone(phone)))
      .catch((err) => {});
  };

  const handleGoBack = () => {
    dispatch(hideErrorMessage());
    dispatch(authActions.resetError());
    navigate("/sign-in");
  };

  const openPdf = () => {
    window.open("/terms.pdf", "_blank");
  };

  const handlePrivacyPolicy = async () => {
    // TODO заменить на редюсер(не могу настроить)
    // dispatch(getBankDocuments(AgreementType.PrivacyPolicy))
    await downloadPDF("privacy_policy", "Политика конфиденциальности");
  };

  const handleTermsOfUse = async () => {
    // TODO заменить на редюсер(не могу настроить)
    // dispatch(getBankDocuments(AgreementType.TermsOfUse))
    await downloadPDF("terms_of_use", "Правила использования сервиса");
  };

  useEffect(() => {
    if (countries) {
      setPhoneCode(countries[0]);
    }
  }, [countries]);

  useEffect(() => {
    dispatch(authActions.setLogin(""));
    dispatch(authActions.setCreateAccountStep("login"));
    dispatch(fetchCountriesList());
  }, [dispatch]);

  useEffect(() => {
    const format = formatValueByMask("XX-XX-XXXX", birthdate)
      .split("-")
      .reverse()
      .join("-");
    const isValid = isMatch(format, "yyyy-MM-dd");
    const older = differenceInYears(new Date(), new Date(format)) < 14;
    if (birthdate.length === 8 && (!isValid || older)) {
      setIsDateValid("Дата указана некорректно");
    } else {
      setIsDateValid("");
    }
  }, [birthdate]);

  return (
    <div className={styles.container}>
      <div className={styles.backBtn}>
        <BackButtonArrow onClick={handleGoBack} />
      </div>
      <div className={styles.titleBlock}>
        <p className={styles.title}>Регистрация</p>
        <p className={styles.title}>
          1<span className={styles.part}>/3</span>
        </p>
      </div>
      <Box component="form" autoComplete="off">
        <Controller
          control={control}
          name="pan"
          render={({ field: { onChange, value } }) => (
            <CardInput
              name="cdNumber"
              label=""
              placeholder="Введите номер карты"
              labelColor="var(--main-color-text-title)"
              value={value}
              onChange={onChange}
              error={!!error}
              showCustomKeyboard
            />
          )}
        />
        <Box display="flex" alignItems="center" gap={12} position="relative">
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                name="phoneNumber"
                value={value}
                mask={phoneCode?.mask}
                codeValue={phoneCode?.code}
                onChange={onChange}
                error={!!error}
                placeholder="Введите номер телефона"
                onFocus={() => setPhoneInputFocused(true)}
                onBlur={() => setPhoneInputFocused(false)}
                focused={phoneInputFocused}
              />
            )}
          />
          <PhoneCodesSelect
            value={phoneCode}
            countries={getSortedCountries(countries)}
            onChange={handleChangePhoneCode}
            error={!!error}
          />
        </Box>
        <Controller
          control={control}
          name="birthdate"
          render={({ field: { onChange } }) => {
            const handleChange = (value: string | string[]) => {
              if (typeof value === "string") {
                const formatValue = value.replace(/[.]/g, "");
                onChange(formatValue);
              }
            };

            return (
              <Box mt={6}>
                <DateInput
                  onChange={handleChange}
                  placeholder="Введите дату рождения"
                />
              </Box>
            );
          }}
        />
        <p className={styles.term}>
          Нажимая кнопку «Продолжить», Вы подтверждаете свое{" "}
          {!isMobile && <br />}
          согласие с условиями
          <span onClick={handleTermsOfUse} className={styles.link}>
            {" "}
            «Пользовательского соглашения»
          </span>{" "}
          и
          <span onClick={handlePrivacyPolicy} className={styles.link}>
            {" "}
            «Политики конфиденциальности»
          </span>
        </p>
        <Button
          type="submit"
          variant="primary"
          size="lg"
          disabled={
            Object.keys(errors).length !== 0 ||
            !pan ||
            !phone ||
            birthdate.length !== 8 ||
            !!isDateValid
          }
          fullWidth
          isLoading={isLoading}
          onClick={(e) => handleRegistry(e)}
          className={styles.btn}
        >
          Продолжить
        </Button>
        {/* <Box display="flex" justifyContent="center" pt={20}>
          <Button variant="secondary" size="lg" className={styles.card}>
            Не знаю номер карты
          </Button>
        </Box> */}
      </Box>
    </div>
  );
};

export default FirstScreen;
