// @ts-ignore
import { Typography, BoxTypeMap, Box, SxProps, Theme } from "@mui/material";
import { Button, CustomButtonProps } from "components";
import { useIsMobile } from "hooks/useIsMobile";

type UnBlockedNotificationProps = {
  pageTitle?: string;
  title?: string | React.ReactNode;
  titleSize?: any;
  subtitle?: string | React.ReactNode;
  subtitleSize?: any;
  icon?: React.ReactNode;
  height?: BoxTypeMap["props"]["height"];
  buttons?: (Pick<CustomButtonProps, "variant" | "size"> & {
    onClick: () => void;
    name: string;
  })[];
  type?: "success" | "error";
  bottomSpace?: number;
  buttonStyle?: SxProps<Theme>;
  titleStyle?: object;
  subtitleStyle?: object;
  buttonsMarginTop?: number;
};

const NewNotificationResult = (props: UnBlockedNotificationProps) => {
  const {
    pageTitle,
    title,
    subtitle,
    icon,
    buttons,
    height,
    titleSize = "text_8",
    subtitleSize = "text_4",
    bottomSpace = 0,
    buttonStyle,
    titleStyle,
    subtitleStyle,
    buttonsMarginTop,
  } = props;

  const { isMobile } = useIsMobile();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={height}
      minHeight={height}
      width={{}}
      textAlign="center"
      padding={{ sm: "0px 16px", lg: "0px 48px" }}
      marginBottom={bottomSpace}
    >
      {icon && isMobile && (
        <Box display="flex" justifyContent="center">
          {icon}
        </Box>
      )}

      {pageTitle && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: isMobile ? "24px" : "30px",
            color: "#393e46",
          }}
          mb={{ xs: 8, md: 16 }}
          mt={{ xs: 24, md: 0 }}
        >
          {pageTitle}
        </Typography>
      )}

      <Box display="flex" flexDirection="column">
        {typeof title === "string" ? (
          <Box sx={{ maxWidth: "675px" }} mb={{ md: 40 }}>
            <Typography
              variant={titleSize}
              color="gray.b500"
              fontWeight={400}
              whiteSpace="pre-wrap"
              sx={{ fontSize: isMobile ? "18px" : "20px" }}
              style={{ ...titleStyle }}
            >
              {title}
            </Typography>
          </Box>
        ) : (
          title
        )}

        {icon && !isMobile && (
          <Box display="flex" justifyContent="center">
            {icon}
          </Box>
        )}

        <Box
          display="flex"
          margin="0 auto"
          style={{ whiteSpace: "pre-wrap" }}
          maxWidth={375}
          justifyContent="center"
        >
          {typeof subtitle === "string" ? (
            <Typography
              variant={subtitleSize}
              color="gray.b500"
              fontWeight={400}
              style={{ ...subtitleStyle }}
            >
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
      </Box>
      {buttons && (
        <Box
          width="100%"
          margin={{ xs: `${buttonsMarginTop || 100}px auto 0` }}
          sx={buttonStyle}
        >
          {buttons?.map(({ name, ...rest }) => (
            <Button key={name} {...rest} fullWidth>
              {name}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default NewNotificationResult;
