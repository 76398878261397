import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { TransfersSteps } from "./TransfersSteps";
import { Button, InputNumber, Textarea } from "components";
import { CardInput } from "molecules/CardInput/CardInput";
import { useIsMobile } from "hooks/useIsMobile";
import {
  resetTransferErrorMessage,
  createTransferToCard,
  TRANSFER_ERROR_CODE,
  resetStateTransfer,
  resetConfirmedTransfer,
  setIsOpenModal,
} from "store/slices/transferSlice";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { setFromTransferCard, setStatement } from "store/slices/cardsSlice";
import { RootState } from "store";
import CardSelect from "./CardSelect/CardSelect";
import {
  MAX_AMOUNT,
  MAX_TRANSFER_PAN_MIN_LENGTH,
  MAX_TRANSFER_PAN_MAX_LENGTH,
  MAX_TRANSFER_REASON_LENGTH,
} from "constants/limits";
import { formatNumber } from "utils/formatCurrency";
import { ITransferState, TabVariants } from "store/slices/system/slice";
import { CardStatus, TransferInfoResponseTransferParam } from "api/account";
import { systemActions } from "store/slices/system";
import { setHugeError } from "store/slices/alerts";
import ClickableHeader from "./ClickableHeader";
import { WarningAlert } from "components/WarningAlert/WarningAlert";

const Transfers = () => {
  const { isMobile } = useIsMobile();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    cards: {
      mainCard,
      allCards,
      account: { currency },
    },
    transfer: {
      isTransferChecked,
      isTransferConfirmed,
      createdTransfer,
      errorMessage,
      isOpenModal,
      isLoading,
    },
    system: { activeTabState },
  } = useSelector((state: RootState) => state);

  const activeCard = allCards.find((item) => item.status === CardStatus.Active);

  const [payloadModal, setPayloadModal] = useState({});

  const [transferValues, setTransferValues] =
    useState<ITransferState>(activeTabState);

  const { pan, amount, reason } = transferValues;

  const ERROR_MESSAGE_AMOUNT = "Ошибка: Превышен лимит";

  // Если по выбранной карте списания превышен лимит на “Все операции по карте” выходит ошибка
  // Если на выбранной карте недостаточно средств для перевода, выходит ошибка [1.3.1 Платежи].

  const toggleModal = (
    repeatValue: string = "",
    {
      toCardPan: pan,
      sum: amount,
      reason,
    }: TransferInfoResponseTransferParam = {}
  ) => {
    if (isOpenModal) {
      setTimeout(() => {
        dispatch(resetTransferErrorMessage());
      }, 200);
    }
    dispatch(setIsOpenModal(!isOpenModal));
    if (
      isOpenModal &&
      isTransferChecked &&
      isTransferConfirmed &&
      !createdTransfer
    ) {
      const payload = repeatValue
        ? { pan, amount, reason }
        : { pan: "", amount: undefined, reason: undefined };
      dispatch(
        systemActions.setActiveTab({
          tab: TabVariants.transfersHub,
          state: payload,
        })
      );
      dispatch(
        setStatement({
          accId: "%",
          forceRefresh: false,
        })
      );
      dispatch(resetConfirmedTransfer());

      setTransferValues({
        pan: "",
        amount: NaN,
        reason: "",
      });
    }
  };

  const continueHandler = () => {
    dispatch(resetStateTransfer());
    const data = {
      cardId: mainCard?.cardId || "",
      toCardPan: pan,
      sum: amount || 0,
      reason: reason || "",
    };
    dispatch(createTransferToCard(data))
      // @ts-ignore
      .unwrap()
      .then((res) => {
        if (res?.limitRestriction) {
          dispatch(
            setHugeError({
              isOpen: true,
              message:
                "Пожалуйста, измените сумму лимита по карте или уменьшите сумму перевода",
              title: "Лимит по карте превышен",
            })
          );
        } else {
          dispatch(
            setHugeError({
              isOpen: false,
              message: "",
              title: "",
            })
          );
          dispatch(setFromTransferCard(mainCard));
          setPayloadModal(data);
          toggleModal();
        }
      });
  };

  useEffect(() => {
    return () => {
      dispatch(resetTransferErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeTabState.pan) setTransferValues(activeTabState);
  }, [activeTabState]);

  const errorMessageAmount =
    amount && amount >= MAX_AMOUNT ? ERROR_MESSAGE_AMOUNT : "";
  const isPanValid =
    !!pan &&
    pan.length >= MAX_TRANSFER_PAN_MIN_LENGTH &&
    pan.length <= MAX_TRANSFER_PAN_MAX_LENGTH;
  const isAmountValid = !!amount && amount > 0 && !errorMessageAmount;
  const isTransferValid =
    isPanValid && isAmountValid && mainCard.status === "ACTIVE";

  const currencySign = currency?.sign || "";

  const goToHandler = (tab: TabVariants) => {
    if (tab === TabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  return (
    <>
      {!isOpenModal ? (
        <>
          <Box
            maxWidth={isMobile ? "auto" : "580px"}
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
          >
            <ClickableHeader
              onBack={() => goToHandler(TabVariants.transfersHub)}
            />
            {!activeCard && (
              <Box
                sx={{
                  position: "relative",
                  "@media (max-width: 768px)": {
                    width: "106%",
                    left: "-16px",
                  },
                  "@media (max-width: 560px)": {
                    width: "108%",
                  },
                  "@media (max-width: 420px)": {
                    width: "110%",
                  },
                }}
              >
                <WarningAlert title="У вас нет карт доступных для перевода" />
              </Box>
            )}

            <Box
              display="flex"
              mb="60px"
              mt={20}
              flexDirection="column"
              rowGap={20}
            >
              <InputNumber
                label="Сумма перевода"
                placeholder="Введите cумму"
                suffix={` ${currencySign}`}
                value={amount}
                error={!!errorMessageAmount}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setTransferValues({
                    ...transferValues,
                    amount:
                      Number(
                        event.target.value
                          .replace(",", ".")
                          .replace(/[^0-9.]/g, "")
                      ) || undefined,
                  });
                }}
              />
              <div>
                <CardSelect activeCard={activeCard} />
                {errorMessageAmount && (
                  <Typography
                    sx={{
                      color: "var(--error-color-icon)",
                      lineHeight: "20px",
                    }}
                  >
                    {errorMessageAmount}
                  </Typography>
                )}
              </div>

              <CardInput
                transfer
                name="cdNumber"
                label="На карту"
                sublabel="Возможен только на карты МИР"
                labelColor="var(--main-color-text-title)"
                subLabelColor="var(--main-color-text-secondary)"
                value={pan}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (
                    errorMessage.title &&
                    errorMessage.code === TRANSFER_ERROR_CODE.CARD
                  ) {
                    dispatch(resetTransferErrorMessage());
                  }
                  setTransferValues({
                    ...transferValues,
                    pan:
                      event.target.value.length <= MAX_TRANSFER_PAN_MAX_LENGTH
                        ? event.target.value
                        : pan,
                  });
                }}
                mb={0}
                error={errorMessage.code === TRANSFER_ERROR_CODE.CARD}
                errorMessage={
                  errorMessage.code === TRANSFER_ERROR_CODE.CARD
                    ? errorMessage.title
                    : ""
                }
                showCustomKeyboard
              />

              <Textarea
                label="Сообщение получателю (1000 символов)"
                value={reason}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  if (
                    errorMessage.title &&
                    errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE
                  ) {
                    dispatch(resetTransferErrorMessage());
                  }
                  setTransferValues({
                    ...transferValues,
                    reason:
                      event.target.value.length <= MAX_TRANSFER_REASON_LENGTH
                        ? event.target.value
                        : event.target.value.substr(
                            0,
                            MAX_TRANSFER_REASON_LENGTH
                          ),
                  });
                }}
                inputColor="var(--main-color-text-title)"
                hint={
                  errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE
                    ? errorMessage.title
                    : ""
                }
                error={errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE}
              />
            </Box>

            <Box display="flex" justifyContent="end">
              <Button
                variant="primary"
                size="lg"
                onClick={continueHandler}
                sx={{
                  height: 56,
                }}
                disabled={!isTransferValid || isLoading}
                fullWidth
              >
                Продолжить
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <TransfersSteps onClose={toggleModal} payloadModal={payloadModal} />
      )}
    </>
  );
};

export default Transfers;
