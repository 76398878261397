import Modal from "@mui/material/Modal";
import { Container, Content, Description, Title } from "./components";
import { Button } from "components";
import { IconWarningTransfer } from "atoms/IconWarningTransfer/IconWarningTransfer";
import { Box } from "@mui/material";

interface Props {
  isOpen: boolean;
  handler: () => void;
}

export const WarningTransferAlert = ({ isOpen, handler }: Props) => {
  return (
    <Modal open={isOpen}>
      <Container>
        <Content>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "12px",
            }}
          >
            <IconWarningTransfer />
          </Box>

          <Title>
            Внимание!
            <br /> Перевод нельзя отменить
          </Title>
          <Description>
            Проверьте внимательно реквизиты получателя, не переводите деньги
            незнакомым людям.
          </Description>
          <Button
            variant="primary"
            size="lg"
            onClick={handler}
            sx={{
              width: "100%",
              borderRadius: "9px",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "20px",
              padding: "18px 0",
            }}
          >
            Понятно
          </Button>
        </Content>
      </Container>
    </Modal>
  );
};
