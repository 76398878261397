import { Button, Loader, SkeletonContainer } from "components";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateRefillResponse,
  createRefill,
  deleteSavedCard,
  setIsCreateRefillLoading,
  setIsNewCard,
  setSavedCards,
} from "store/slices/transferSlice";
import {
  CARD_ACTION,
  cardActionsToggle,
  setScreen,
} from "store/slices/cardsSlice";
import theme from "theme";
import { RootState } from "store";
import EmptyCardIcon from "./assets/empty-card-icon.png";
import { CardIcon } from "./assets/CardIcon";
import { ItemCard } from "./ItemCard";
import { CARD_STATUS } from "components/CardsInfo/model";
import {
  AllCardsWrapper,
  ItemCardIcon,
  ItemsWrapper,
  SmallTitle,
} from "./components";
import { CardDetails } from "components/CardDetails/CardDetails";
import { useIsMobile } from "hooks/useIsMobile";
import { CardsBottomSheet } from "components/CardsBottomSheet/CardsBottomSheet";
import CardSelectModal from "components/CardSelectModal/CardSelectModal";
import { CardDto, CardStatus } from "api/account";
import { WarningAlert } from "components/WarningAlert/WarningAlert";
import { Box } from "@mui/system";

const CARD_COLORS = [
  {
    background: "#E6FEE0",
    color: "var(--success-color-primary)",
  },
  {
    background: "#FFDEDB",
    color: "var(--error-color-icon)",
  },
  {
    background: "var(--brand-color-hover)",
    color: "var(--brand-color-primary)",
  },
  {
    background: "#E5DFFF",
    color: "var(--secondary-color-4)",
  },
  {
    background: "#F8F8D3",
    color: "#F9C81C",
  },
  {
    background: "#FFD8BC",
    color: "#F98166",
  },
];

const primaryButtonStyles = { marginTop: 15 };
const secondaryButtonStyles = {
  marginTop: 15,
  backgroundColor: theme.palette.gray.b100,
};

interface Props {
  onClose?: () => void;
}

export const RefillBlock: React.FC<Props> = ({ onClose }) => {
  const { isMobile } = useIsMobile();
  const {
    cards: { chosenCardIndex, allCards },
    transfer: {
      isSavedCardsLoading,
      savedCards,
      isNewCard,
      isCreateRefillLoading,
    },
  } = useSelector((state: RootState) => state);

  const isSelectedCard = useMemo(
    () => savedCards.filter((card) => card.active).length,
    [savedCards]
  );

  const anyActiveCard = allCards.find(
    (item) => item.status === CardStatus.Active
  );

  const isDisabledButton =
    (!isNewCard && !isSelectedCard) || isSavedCardsLoading || !anyActiveCard;
  const displayCards = useMemo(
    () => allCards.filter((card) => card.status === CARD_STATUS.Active),
    [allCards]
  );

  const chosenCard =
    chosenCardIndex && allCards[chosenCardIndex].status === CARD_STATUS.Active
      ? allCards[chosenCardIndex]
      : displayCards[0];

  const [activeCard, setActiveCard] = useState(chosenCard);
  const [showCards, setShowCards] = useState(false);

  const dispatch = useDispatch();

  const createRefillRequest = () => {
    dispatch(setIsCreateRefillLoading(true));

    dispatch(
      createRefill({
        receiverCard: activeCard,
        savedSenderCardId: isNewCard
          ? null
          : savedCards.filter((card) => card.active)[0].id,
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res: CreateRefillResponse) => {
        dispatch(cardActionsToggle());

        setTimeout(() => {
          window.open(res.url, "_blank");
        }, 0);
      })
      .catch(() => {
        dispatch(setScreen(CARD_ACTION.REFILL_ERROR));
      })
      .finally(() => dispatch(setIsCreateRefillLoading(false)));
  };

  const onCardClick = (card: CardDto) => {
    setActiveCard(card);
    setShowCards(false);
  };

  const showCardsHandler = useCallback(() => {
    setShowCards((prevState) => !prevState);
  }, [showCards]);

  const card =
    activeCard?.status === CardStatus.Active ? activeCard : anyActiveCard;

  return (
    <>
      {!card && (
        <Box
          sx={{
            position: "relative",
            left: "-32px",
            width: "117%",
            "@media (max-width: 768px)": {
              left: "-16px",
              width: "108%",
            },
            "@media (max-width: 400px)": {
              width: "110%",
            },
          }}
        >
          <WarningAlert title="У вас нет карт доступных для пополнения" />
        </Box>
      )}
      <SmallTitle>c карты</SmallTitle>
      <SkeletonContainer
        height="150px"
        isLoading={isSavedCardsLoading}
        width="100%"
      >
        <ItemsWrapper>
          {savedCards.map((card, index) => (
            <ItemCard
              key={card.id}
              checked={card.active}
              title={card.brandName}
              cardNumber={card.pan?.slice(-4)}
              onChange={() => dispatch(setSavedCards(card.id))}
              onDelete={() => dispatch(deleteSavedCard(card.id))}
              background={CARD_COLORS[index].background}
              icon={<CardIcon color={CARD_COLORS[index].color} />}
            />
          ))}
          <ItemCard
            checked={isNewCard}
            title="Новая карта"
            onChange={() => dispatch(setIsNewCard())}
            background="#F0F0F0"
            icon={<ItemCardIcon src={EmptyCardIcon} />}
            disabledMenu
          />
        </ItemsWrapper>
      </SkeletonContainer>
      <Box sx={{ height: "20px" }} />
      <SmallTitle>на карту</SmallTitle>
      <AllCardsWrapper>
        <CardDetails
          card={card}
          hasMultipleCards={allCards.length > 1}
          onClick={showCardsHandler}
        />

        {allCards.length > 1 && showCards && (
          <>
            {isMobile && (
              <CardsBottomSheet
                isOpen={showCards}
                cards={allCards}
                onClick={onCardClick}
                onClose={() => setShowCards(false)}
              />
            )}
            {!isMobile && (
              <CardSelectModal
                cards={allCards}
                mainCard={activeCard}
                onClick={onCardClick}
                onClose={showCardsHandler}
                isOpen={showCards}
              />
            )}
          </>
        )}
      </AllCardsWrapper>
      <>
        <Button
          onClick={createRefillRequest}
          variant="primary"
          size="lg"
          fullWidth
          startIcon={isCreateRefillLoading && <Loader />}
          sx={{ ...primaryButtonStyles, marginTop: "40px" }}
          disabled={isDisabledButton}
        >
          Применить
        </Button>
        <Button
          size="lg"
          variant="secondary"
          onClick={
            onClose ? () => onClose() : () => dispatch(cardActionsToggle())
          }
          fullWidth
          sx={secondaryButtonStyles}
        >
          Отмена
        </Button>
      </>
    </>
  );
};
