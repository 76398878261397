import { Box, Switch, SwitchProps, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";

import { Dispatch, SetStateAction, useState } from "react";

import { requestPermission } from "../../firebase";
import { handleTogglePush } from "../../store/slices/notifications";
import { showErrorMessage } from "../../store/slices/alerts";
import { Button } from "components";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { PushAlert } from "atoms/PushIcons/PushAlert";
import { IconBell } from "atoms/IconBell/IconBell";

const useStyles = makeStyles(() => ({
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 22,
    height: 22,
    borderRadius: "50%",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--brand-color-primary)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "var(--brand-color-primary)",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "var(--main-color-border-icon)",
    opacity: 1,
  },
}));

interface PushErrorInt {
  setShowError: Dispatch<SetStateAction<boolean>>;
}

export const PushError = ({ setShowError }: PushErrorInt) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          backgroundColor: "var(--main-color-text-secondary)",
          position: "absolute",
          opacity: 0.5,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          padding: "20px 20px 28px 20px",
          display: "flex",
          flexDirection: "column",
          borderRadius: 12,
        }}
      >
        <PushAlert />
        <Typography
          color="var(--main-color-text-title)"
          variant="text_5"
          paddingTop={10}
          fontWeight="500"
        >
          Настройка push-уведомлений
        </Typography>
        <Typography
          color="var(--main-color-text-title)"
          variant="text_3"
          paddingTop={8}
          paddingBottom={20}
        >
          Вам нужно разрешить отправку push-уведомлений в системных настройках
          устройства.
        </Typography>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          fullWidth
          onClick={() => setShowError(false)}
        >
          Понятно
        </Button>
      </Box>
    </Box>
  );
};

export const ProfileTogglePush = () => {
  const classes = useStyles();
  const deviceIdItem = localStorage.getItem("PWADeviceId");
  const dispatch = useDispatch();
  const [isOn, setIsOn] = useState(Boolean(localStorage.getItem("isPushOn")));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setShowError] = useState(false);
  const displayError = (message: string) => {
    dispatch(
      showErrorMessage({
        errorTitle: "Ошибка",
        errorMessage: message,
      })
    );
  };

  const handlePushToggle = async (status: boolean) => {
    setIsLoading(true);
    requestPermission()
      .then((res) => {
        dispatch(
          handleTogglePush({
            deviceId: deviceIdItem || "",
            fcm: res.token || "",
            enabled: status,
            device_type: "W",
          })
        )
          // @ts-ignore
          .unwrap()
          .then(() => {
            setIsOn(status);
            if (status) {
              localStorage.setItem("isPushOn", "true");
            } else {
              localStorage.removeItem("isPushOn");
            }
          })
          .catch(() => {
            displayError("Проверьте соединение");
          });
      })
      .catch((err) => {
        if (err.alert && status) {
          setShowError(true);
        } else {
          if (!status) {
            setIsOn(false);
            localStorage.removeItem("isPushOn");
          } else {
            displayError(err.text);
          }
        }
      });
  };

  const Icon = ({ isLoading }: { isLoading: boolean }) => (
    <div className={`${classes.circle}`}>
      {isLoading && (
        <CircularProgress
          size={14}
          style={{ color: "var(--brand-color-primary)" }}
          thickness={6}
        />
      )}
    </div>
  );

  return (
    <Box
      sx={{
        borderRadius: 16,
        backgroundColor: "var(--main-color-bg-widgets)",
        position: "relative",
      }}
      p={{ xs: 12, xl: 20 }}
      display="flex"
      gap={12}
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 36,
          height: 36,
          backgroundColor: "#FFFFFF",
        }}
      >
        <IconBell />
      </Box>
      <Typography variant="text_22" fontWeight={500} color="gray.b800" flex={1}>
        Push-уведомления
      </Typography>
      <IOSSwitch
        checkedIcon={<Icon isLoading={isLoading} />}
        icon={<Icon isLoading={isLoading} />}
        sx={{ m: 1 }}
        checked={isOn}
        disabled={isLoading}
        onChange={() =>
          handlePushToggle(!isOn).then(() => {
            setIsLoading(false);
          })
        }
      />
      {error && <PushError setShowError={setShowError} />}
    </Box>
  );
};
