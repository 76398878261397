import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";
import { Switch } from "components/Switch/Switch";
import React, { useState } from "react";
import { FilterKeys } from "store/slices/profile/slice";

export enum FilterKeysEm {
  CashIn = "cashIn",
  FreeFee = "freeFee",
  AllTime = "allTime",
  RUB = "RUB",
  TJS = "TJS",
  KGS = "KGS",
  KZT = "KZT",
  AMD = "AMD",
}

export const FilterModal = ({
  filtersState,
  setFiltersState,
  onClose,
  isMobile,
}) => {
  const [filters, setFilters] = useState({ ...filtersState });

  const handleToggle = (key: FilterKeys) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: !prevFilters[key],
    }));
  };

  const handleFiltersApply = () => {
    setFiltersState({ ...filters });
    onClose();
  };

  return (
    <Box pl={isMobile ? 20 : 32} pr={isMobile ? 16 : 32}>
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 0",
          }}
        >
          <Button variant="text" sx={{ color: "#739B67" }} onClick={onClose}>
            Отмена
          </Button>
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: "20px",
              color: "var(--main-color-text-title)",
            }}
          >
            Фильтр
          </Typography>
          <Button
            variant="text"
            sx={{ color: "#739B67" }}
            onClick={handleFiltersApply}
          >
            Применить
          </Button>
        </Box>
      )}
      <FilterControl
        label="Пополнение карты"
        isToggle={filters.cashIn}
        onToggle={() => handleToggle(FilterKeysEm.CashIn)}
        Component={Switch}
        isMobile={isMobile}
      />
      <FilterControl
        label="Комиссия не взимается"
        isToggle={filters.freeFee}
        onToggle={() => handleToggle(FilterKeysEm.FreeFee)}
        Component={Switch}
        isMobile={isMobile}
      />
      <FilterControl
        label="Работает круглосуточно"
        isToggle={filters.allTime}
        onToggle={() => handleToggle(FilterKeysEm.AllTime)}
        Component={Switch}
        isMobile={isMobile}
      />

      <FilterControl
        label="Выдача наличных в валюте ₽ (RUB)"
        Component={Checkbox}
        isToggle={filters.RUB}
        onToggle={() => handleToggle(FilterKeysEm.RUB)}
        isMobile={isMobile}
      />
      <FilterControl
        label="Выдача наличных в валюте с (TJS)"
        Component={Checkbox}
        isToggle={filters.TJS}
        onToggle={() => handleToggle(FilterKeysEm.TJS)}
        isMobile={isMobile}
      />
      <FilterControl
        label="Выдача наличных в валюте ₸ (KZT)"
        Component={Checkbox}
        isToggle={filters.KZT}
        onToggle={() => handleToggle(FilterKeysEm.KZT)}
        isMobile={isMobile}
      />
      <FilterControl
        label="Выдача наличных в валюте ֏ (AMD)"
        Component={Checkbox}
        isToggle={filters.AMD}
        onToggle={() => handleToggle(FilterKeysEm.AMD)}
        isMobile={isMobile}
      />
      <FilterControl
        label="Выдача наличных в валюте с (KGS)"
        Component={Checkbox}
        isToggle={filters.KGS}
        onToggle={() => handleToggle(FilterKeysEm.KGS)}
        isMobile={isMobile}
        bottomBrd={false}
      />

      {!isMobile && (
        <>
          <Button
            variant="primary"
            fullWidth
            sx={{
              marginTop: "100px",
              padding: "16px 10px",
              lineHeight: "20px",
            }}
            onClick={handleFiltersApply}
          >
            Применить
          </Button>
          <Button
            variant="secondary"
            fullWidth
            sx={{ marginTop: "12px", padding: "16px 10px", lineHeight: "20px" }}
            onClick={onClose}
          >
            Отмена
          </Button>
        </>
      )}
    </Box>
  );
};

const FilterControl = ({
  label,
  isToggle,
  onToggle,
  Component,
  bottomBrd = true,
  isMobile = false,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: isMobile ? "16px 0" : "20px 0",
      boxSizing: "border-box",
      borderBottom: bottomBrd ? "1px solid #E9E9E9" : "1px solid transparent",
    }}
  >
    <Typography sx={{ lineHeight: "20px" }}>{label}</Typography>
    <Component isToggle={isToggle} onToggle={() => onToggle((prev) => !prev)} />
  </Box>
);
