import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Button } from "components";
import { useSelector } from "react-redux";
import {
  getPaymentReport,
  setIsOpenModal,
} from "../store/slices/transferSlice";
import IconRefreshReverse from "./Icons/IconRefreshReverse";
import { useAppThunkDispatch } from "../store";
import { formatCurrency } from "utils/formatCurrency";
import { FileType, downloadFile } from "utils/downloadFile";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";
import { IconCardTranferCheck } from "atoms/IconCardTranferCheck";
import IconDownloadCheck from "atoms/IconDownloadCheck";
import ClickableHeader from "pages/home/Cards/Transfers/ClickableHeader";
import { AnotherPages, TabVariants } from "store/slices/system/slice";
import { systemActions } from "store/slices/system";

export const OperationCheck = ({
  confirmedTransfer,
  payloadModal,
  onTab,
}: any) => {
  const dispatch = useAppThunkDispatch();
  const {
    transfer: { isCheckLoading, confirmedTransfer: tr, isOpenModal },
    cards: {
      fromTransferCard: { cardPan: fromCardPan },
      account: { currency },
    },
  } = useSelector((state: any) => state);

  const onClose = () => dispatch(setIsOpenModal(!isOpenModal));

  const goToHandler = (tab: AnotherPages | TabVariants) => {
    dispatch(setIsOpenModal(!isOpenModal));
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  const {
    toClientName,
    toCardPan,
    toClientBank,
    amount,
    fee,
    dateTime,
    reason,
  } = confirmedTransfer;

  useEffect(() => {
    return () => {
      dispatch(setIsOpenModal(!isOpenModal));
    };
  }, []);

  const confirmedTransferDetails = useMemo(
    () => [
      { title: "Карта списания", value: fromCardPan },
      {
        title: "Получатель",
        value: toClientName || "не указано",
      },
      { title: "Номер карты получателя", value: toCardPan },
      { title: "Банк получателя", value: toClientBank },
      { title: "Сумма перевода", value: amount },
      // { title: "Комиссия", value: fee },
      { title: "Дата и время", value: dateTime },
      { title: "Сообщение", value: reason },
    ],
    [
      amount,
      dateTime,
      fromCardPan,
      reason,
      toCardPan,
      toClientBank,
      toClientName,
    ]
  );

  const downloadCheck = () => {
    if (confirmedTransfer?.paymentId) {
      dispatch(getPaymentReport(confirmedTransfer?.paymentId))
        .unwrap()
        .then((res) => {
          downloadFile(res, "check.pdf", FileType.PDF);
        });
    }
  };

  const formatValue = (title, value) => {
    switch (title) {
      case "Дата и время": {
        const zonedDate = utcToZonedTime(value, "Europe/Moscow"); // TODO add Time Zone
        return format(zonedDate, "d MMMM yyyy, HH:mm", { locale: ru });
      }
      case "Сумма перевода":
      case "Комиссия":
        return formatCurrency(value, true);
      default:
        return value;
    }
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ rowGap: "16px" }}>
      <ClickableHeader
        onBack={() => goToHandler(TabVariants.transfersHub)}
        title={false}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: "28px 10px",
          backgroundColor: "var(--main-color-bg-widgets)",
          borderRadius: "9px",
          rowGap: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: 500,
            lineHeight: "36px",
            color: "#2D3227", // TODO add to style colors
          }}
        >
          - {formatValue("Сумма перевода", amount)}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            columnGap: "8px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "8px",
              alignItems: "center",
            }}
          >
            <IconCardTranferCheck />
          </Box>

          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#739B67",
            }}
          >
            Перевод по номеру карты
          </Typography>
        </Box>
      </Box>
      <Box display="flex" width="100%" gap={12}>
        <Button
          variant="secondary"
          size="lg"
          startIcon={<IconRefreshReverse />}
          onClick={onClose}
          fullWidth
        >
          Повторить
        </Button>
        <Button
          variant="secondary"
          size="lg"
          startIcon={<IconDownloadCheck />}
          onClick={downloadCheck}
          isLoading={isCheckLoading}
          fullWidth
        >
          Справка
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
        {confirmedTransferDetails.map(({ title, value }, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                color: "var(--main-color-text-secondary)",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#2D3227", // TODO add to theme colors palethe
              }}
            >
              {formatValue(title, value)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
