import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Button } from "components";
import { LoginInput } from "molecules/LoginInput/LoginInput";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "store";
import { loginPasswordPattern, loginPattern } from "constants/regexp";
import { authActions, login } from "../../store/slices/auth";
import { UserStatus } from "./../../store/slices/auth/model";
import BlockedScreen from "./BlockedScreen";
import UnBlockedScreen from "./UnBlockedScreen";
import BlockedScreenPinChange from "./BlockedScreenPinChange";
import { showErrorMessage } from "../../store/slices/alerts";
import { useIsPWA } from "hooks/useIsPWA";
import { deleteFCMToken } from "../../firebase";
import styles from "./style.module.css";
import { ForgotHint } from "components/ForgotHint";

export const Authorization = () => {
  const [isShowForgotHint, setIsShowForgotHint] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPWA = useIsPWA();

  const [values, setValues] = useState({
    login: "",
    password: "",
  });

  const {
    isLoading,
    error,
    elseDevice,
    isAccountBlocked,
    isLoginBlocked,
    accountStatus,
  } = useSelector(authSelector);

  const handleClickToMain = (e) => {
    e.preventDefault();
    if (localStorage.getItem("isPushOn")) {
      deleteFCMToken();
      localStorage.removeItem("isPushOn");
    }

    dispatch(
      login({
        login: values.login,
        password: values.password,
        isPWA,
      })
    )
      .unwrap()
      .then(() => {
        if (elseDevice) {
          localStorage.setItem("elseLogin", values.login);
        } else {
          localStorage.setItem("tmpLogin", values.login);
        }
        navigate("/confirm", { replace: true });
      })
      .catch((err) => {
        if (err.response.data.code === "WRONG_DEVICE") {
          dispatch(
            authActions.setAnotherDeviceError({
              title: err.response.data.title,
              description: err.response.data.subtitle,
            })
          );
          navigate("/login-error");
        } else {
          if (err.response.data.code === "CLIENT_BLOCKED") return;
          if (err.response.data.code === "CLIENT_UNBLOCKED") return;

          dispatch(
            showErrorMessage({
              errorTitle: err.response.data.title,
              errorMessage: err.response.data.subtitle,
            })
          );
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const pattern = name === "login" ? loginPattern : loginPasswordPattern;

    setValues({
      ...values,
      [e.target.name]: value.replace(pattern, ""),
    });
  };

  if (isShowForgotHint) {
    return <ForgotHint handleBack={() => setIsShowForgotHint(false)} />;
  }

  if (isAccountBlocked) {
    return <BlockedScreen />;
  }

  if (accountStatus === UserStatus.UNBLOCKED) {
    return <UnBlockedScreen />;
  }

  if (isLoginBlocked) {
    return <BlockedScreenPinChange />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <p className={styles.title}>Войти</p>
        <form autoComplete="off">
          <LoginInput
            name="login"
            value={values.login}
            placeholder="Введите логин"
            onChange={handleChange}
            error={error ? "" : null}
            sx={{ mb: 12 }}
          />
          <PasswordInput
            value={values.password}
            placeholder="Введите пароль"
            onChange={handleChange}
            name="password"
            error={error ? "" : null}
          />
          <Box display="flex" flexDirection="column" mt={40} rowGap={12}>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              fullWidth
              onClick={(e) => handleClickToMain(e)}
              isLoading={isLoading}
              className={styles.btn}
            >
              Войти
            </Button>
            <Button
              variant="secondary"
              size="lg"
              fullWidth
              className={styles.btn}
              onClick={() => setIsShowForgotHint(true)}
            >
              Не помню логин или пароль
            </Button>
            <Link to="/sign-up" className={styles.link}>
              <Button variant="secondary" size="lg" className={styles.regBtn}>
                Зарегистрироваться
              </Button>
            </Link>
          </Box>
        </form>
      </div>
    </div>
  );
};
