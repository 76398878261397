import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/slices/auth";
import SuccessIcon from "../../Registration/SuccessedScreen/assets/SuccessIcon.png";
import styles from "./styles.module.css";

const FifthScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/sign-in");
    dispatch(authActions.setRestoreScreen("initial"));
  };

  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      px={{ xl: 86 }}
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
      minHeight="calc(100vh - 390px)"
    >
      <Box
        p={{ xs: "80px 0 180px", md: "32px 30.5px 80px" }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <div className={styles.container}>
          <p className={styles.title}>Восстановление пароля</p>
          <img src={SuccessIcon} className={styles.icon} />
          <Typography
            variant="text_8"
            color="gray.b900"
            fontWeight={600}
            whiteSpace="pre-wrap"
          >
            Пароль восстановлен
          </Typography>
          <Typography
            variant="text_4"
            color="gray.b500"
            fontWeight={500}
            mb={24}
            mt={16}
          >
            {`Теперь вы можете продолжить работу\n в личном кабинете`}
          </Typography>
          <Button variant="primary" size="lg" fullWidth onClick={handleClick}>
            Понятно
          </Button>
        </div>
      </Box>
    </Grid>
  );
};

export default FifthScreen;
