import { Box, Typography, useMediaQuery } from "@mui/material";

import { ExchangeRates } from "components/ExchangeRates";
import { MainGridItem } from "components/Grid";

import BankContacts from "./BankContacts";
import BankAddresses from "./components/BankAddresses/BankAddresses";
import useSticky from "hooks/useSticky";
import { useEffect } from "react";

function Other() {
  const isDesktop = useMediaQuery("(min-width:980px)");
  const isTablet = useMediaQuery("(min-width:413px)");
  const { isSticky, ref } = useSticky();

  return (
    <>
      <MainGridItem columns={{ xs: [1, -1], l: [1, 4] }}>
        {!isDesktop && (
          <Box ref={ref} sx={{ height: isSticky ? "96px" : "auto" }}>
            <Typography
              fontWeight={500}
              fontSize={isTablet ? 32 : 24}
              mb={32}
              mt={isTablet ? 0 : -6}
              sx={{
                color: "var(--main-color-text-title)",
                position: isSticky ? "fixed" : "",
                padding: isSticky ? "34px 16px" : "",
                background: isSticky ? "rgba(255, 255, 255, 0.37)" : "",
                backdropFilter: isSticky ? "blur(10px)" : "none",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              Дополнительно
            </Typography>
          </Box>
        )}

        <ExchangeRates />
      </MainGridItem>

      <MainGridItem
        columns={{
          xs: [1, -1],
          l: [5, -1],
        }}
      >
        {isDesktop && (
          <Typography
            fontWeight={500}
            fontSize={32}
            mb={24}
            sx={{ color: "var(--main-color-text-title)" }}
          >
            Дополнительно
          </Typography>
        )}

        <BankContacts />
        <BankAddresses />
      </MainGridItem>
    </>
  );
}

export default Other;
